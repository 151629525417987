




import { Component, Vue } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/vessel-notes/vessel-notes";

@Component
export default class VesselNotesWrapper extends Vue {
  currentVesselId: number | undefined;
  instance: any = {};

  mounted(): void {
    this.currentVesselId = parseInt(this.$route.params.vesselId);

    const param = {
      vesselId: this.currentVesselId,
    };

    this.instance = new koHtmlAndViewModel.viewModel(param);
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;
    ko.applyBindings(this.instance, this.$el);
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
